import React from 'react'

import html from '../assets/html.png'
import css from '../assets/css.png'
import javascript from '../assets/javascript.png'
import react from '../assets/react.png'
import java from '../assets/java.png'
import android from '../assets/anroid.png'
import github from '../assets/github.png'
import tailwind from '../assets/tailwind.png'
import python from '../assets/python.png'

const Skills = () => {

    const techs=[
        {
            id:1,
            src:html,
            title:"HTML",
            style:'shadow-orange-500'
        },
        {
            id:2,
            src:css,
            title:"CSS",
            style:'shadow-blue-500'
        },
        {
            id:3,
            src:javascript,
            title:"JavaScript",
            style:'shadow-yellow-500'
        },
        {
            id:4,
            src:react,
            title:"React",
            style:'shadow-[#02D7FF]'
        },
        {
            id:5,
            src:java,
            title:"Java",
            style:'shadow-[#5283A2]'
        },
        {
            id:6,
            src:android,
            title:"Android",
            style:'shadow-green-400'
        },
        {
            id:7,
            src:github,
            title:"Github",
            style:'shadow-gray-400'
        },
        {
            id:8,
            src:tailwind,
            title:"Tailwind",
            style:'shadow-[#44A8B3]'
        }
        ,
        {
            id:9,
            src:python,
            title:"Python",
            style:'shadow-[#FFE567]'
        }
    ]
  return (
    <div name="skills" className='pt-16 bg-gradient-to-b from-gray-800 to-black w-full h-full'>
        <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
            <div className='mt-8'>
                <p className='text-4xl border-b-4 border-gray-500 font-bold  inline'>Skills</p>
                <p className='py-6'>These are the techonlogies I've worked with</p>
            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0'>
                {
                    techs.map(({id,src,title,style})=>(
                        <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                    <img className="w-20 mx-auto" src={src} alt='skills'/>
                    <p className='mt-4'>{title}</p>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Skills