import React from 'react'
import heroImage from "../assets/img.jpg"
import {MdOutlineKeyboardArrowRight} from "react-icons/md"
import {Link} from "react-scroll"

const Home = () => {
  return (
  <div name="home" className='h-full w-full bg-gradient-to-b from-black via-black to-gray-800 text-white md:h-screen pb-4'>
    <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row '>
        <div className='flex flex-col justify-center h-full'>
            <h2 className='text-4xl sm:text-7xl font-bold text-white'>I'm an Android developer</h2>
            <p className='text-gray-500 py-4 max-w-md'><span>Welcome to my portfolio</span>
            <br/>
                    "My name is Ashish Kamat, and
                        my portfolio is a representation
                        of all that l've learned and
                        accomplished as a computer science and
                        engineering student"
            </p>
            <div>
                <Link to='portfolio' smooth duration={500} className='group text-white w-fit cursor-pointer px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500'>
                    Portfolio
                    <span className='group-hover:rotate-90 duration-300' >
                    <MdOutlineKeyboardArrowRight size={20} className="ml-1"/>
                    </span>
                </Link>
            </div>
        </div>
        <div>
            <img src={heroImage} alt='my profile' className="rounded-2xl mx-auto w-2/3 md:w-full hover:scale-105 duration-200 sm:mb-4"/>
        </div>
    </div>
  </div>
  )
}

export default Home