import About from "./components/About";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import SocialLink from "./components/SocialLink";
import Contact from "./components/Contact"
function App() {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      <About/>
      <Portfolio className="mt-2"/>
      <Skills/>
      <Contact/>
      <SocialLink/>
    </div>
  );
}

export default App;
