import React from 'react'
import groceryApp from '../assets/portfolio/groceryApp.png'
import covidApp from '../assets/portfolio/covidApp.png'
import netflixgpt from '../assets/portfolio/netflixgpt.jpg'
import newsWebApp from '../assets/portfolio/newsWebApp.jpg'
import blogApp from '../assets/portfolio/project-img1.png'
import newsApp from '../assets/portfolio/newsApp.png'

const Portfolio = () => {
    const portfolios=[
        {
            id:1,
            src:groceryApp,
            code:"https://github.com/Ash-333/Grocery-App",
            demo:"https://drive.google.com/file/d/1A4ap8uHnLyPTS7gP-bcTwbX9Jc7yqauS/view?usp=share_link"
        },
        {
            id:2,
            src:covidApp,
            code:"https://github.com/Ash-333/CoviTrack",
            demo:"https://drive.google.com/file/d/16ZeALIg2zoXCf0FbIUbIRQJhczLOTD7N/view?usp=share_link"
        },
        {
            id:3,
            src:netflixgpt,
            code:"https://github.com/Ash-333/netflix-gpt",
            demo:"https://netflix-gpt-akamat.vercel.app"
        },
        {
            id:4,
            src:newsWebApp,
            code:"https://github.com/Ash-333/techNewsReact",
            demo:"https://tech-news-app.netlify.app/"
        },
        {
            id:5,
            src:blogApp,
            code:"https://github.com/Ash-333/blog-api",
            demo:"https://mern-bloghub.netlify.app/"
        },
        {
            id:6,
            src:newsApp,
            code:"https://github.com/Ash-333/techNewsApp",
            demo:"https://github.com/Ash-333/techNewsApp"
        }
    ]

  return (
    <div name="portfolio" className='bg-gradient-to-b from-black to-gray-800 text-white w-full h-full md:h-screen '>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold  inline border-b-4 border-gray-500'>Portfolio</p>
                <p className='py-6'>Checkout some of my work right here</p>
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-10 sm:px-0'>
            {
                portfolios.map(({id,src,demo,code})=>(
                    <div key={id} className='shadow-md shadow-gray-600  rounded-lg hover:shadow-gray-400'>
                        <img src={src} alt='' className='rounded-md duration-200 hover:scale-105  h-52 w-full'/>
                        <div className='flex items-center justify-center'>
                        
                            <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'><a href={demo} target="_blank"
                rel="noopener">Demo</a></button>
                            <button className='w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105'><a href={code} target="_blank"
                rel="noopener">Code</a></button>
                        </div>
                    </div>
                ))
            }

            </div>
        </div>
    </div>
  )
}

export default Portfolio