import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check for empty fields and valid email format
    if (name.trim() === '' || email.trim() === '' || message.trim() === '') {
      setError('All fields are required.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailPattern)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError(''); // Clear any previous error

    // Submit the form (you can implement your submission logic here)
    console.log('Form submitted:', { name, email, message });
  };

  return (
    <div name="contact" className='w-full h-full bg-gradient-to-b from-black to-gray-800 p-4 text-white'>
      <div className='flex flex-col p-4  justify-center max-w-screen-lg mx-auto h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold border-b-4 border-gray-500'>Contact</p>
          <p className='py-6'>Submit the form below to get in touch with me</p>
        </div>
        <div className='flex justify-center items-center'>
          <form className='flex flex-col w-full md:w-1/2' onSubmit={handleSubmit}>
            <input
              type='text'
              placeholder="Enter your name"
              name='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <input
              type='text'
              placeholder="Enter your email"
              name='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-2 my-4 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            <textarea
              rows="10"
              placeholder='Enter your message here'
              name='message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
            />
            {error && <div className="text-red-500 mt-3 font-semibold">{error}</div>}
            <button
              type='submit'
              className='text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration:300'
            >
              Let's Talk
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
