import React from 'react'

const About = () => {
  return (
    <div name="about" className='w-full h-full bg-gradient-to-b from-gray-800 to-black text-white md:h-screen'>
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full sm:pb-5 sm:pt-5">
            <div >
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
            </div>
        
          <p className='mt-20 text-xl'>Hello! My name is Ashish Kamat and I am currently a final-year computer science engineering student at Chandigarh University. I am passionate about using technology to solve problems and improve people's lives. In my coursework, I have gained a strong foundation in algorithms, data structures, and software design, and I am always looking for opportunities to apply my skills and learn more.

                In addition to my studies, I have also developed a range of technical skills, including proficiency in C/C++, Java, Android development, ReactJS, Kotlin, NodeJS, and Python. I am eager to continue building upon these skills and tackling new challenges.</p>
              <br/>
            <p className='text-xl'>I am excited to share my personal projects and experiences with you through this portfolio. Please take a look around and feel free to contact me if you have any questions or if you would like to learn more about my work. Thank you for visiting!</p>
        </div>
    </div>

  )
}

export default About